import { useCallback, useMemo } from 'react';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import getSimplifiedDataGridBySatKeys from 'shared/util/getSimplifiedDataGridBySatKeys';
import { SAT_KEYS_DICT, SatKeysDict } from 'shared/constants/grids';
import { Range } from 'types/grids';
import useSimplifiedColumns from './useSimplifiedColumns';
import useSimplifiedRows from './useSimplifiedRows';

interface UseGridOpts {
  satKeys: number[];
  workflows: Workflow[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period?: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
}
export default function useResicoGrid({
  satKeys,
  workflows,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
}: UseGridOpts) {
  const columns = useSimplifiedColumns();
  const dataSet = useMemo(
    () =>
      getSimplifiedDataGridBySatKeys({
        satKey: satKeys,
        workflows,
      }),
    [satKeys, workflows],
  );

  const rows = useSimplifiedRows({
    dataSet,
    selectedMonth: null,
    title: SAT_KEYS_DICT[satKeys[0] as SatKeysDict],
    period,
    selectedAnnualDeclaration,
  });

  const onSelectionChanged = useCallback(
    (range: Range[]) => {
      const {
        first: { column, row },
      } = range[0];
      const monthIdx = column.idx - 1;
      const isExpense = row.idx === 2;

      if (monthIdx < 0 || monthIdx > 11 || row.idx < 1 || row.idx > 2) {
        return;
      }
      onHandleEntriesView(`${monthIdx}`, isExpense ? 'expense' : 'income');
    },
    [onHandleEntriesView],
  );

  return { columns, rows, onSelectionChanged };
}
