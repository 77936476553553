import {
  PendingDeclarationsFlowStep,
  StepDataType,
  TextStep,
} from 'types/flows';
import { useMemo } from 'react';

import TextStepContent from '../TextStepContent';

interface PendingDeclarationsStepContentProps {
  pendingDeclarationsFlowStep: PendingDeclarationsFlowStep;
}

export default function PendingDeclarationsStepContent({
  pendingDeclarationsFlowStep,
}: PendingDeclarationsStepContentProps) {
  const { state: pendingDeclarations } = pendingDeclarationsFlowStep;

  const textStep = useMemo<TextStep>(() => {
    if (pendingDeclarations.length > 0) {
      const bulletPoints = pendingDeclarations.map((month) => `• ${month}`);
      return {
        id: 0,
        flow_id: 0,
        type: StepDataType.TEXT,
        params: {
          title: 'En tu opinión de cumplimiento tienes meses pendientes.',
          emoji: '✏️',
          body: `Realizar tus declaraciones de manera oportuna es crucial, ayuda a evitar multas significativas e intereses que se acumulan por pagos atrasados, que pueden aumentar rápidamente el monto total adeudado.\n\nPor tal motivo te recomendamos ponerte al corriente con las siguientes declaraciones:\n\n${bulletPoints.join(
            '\n',
          )}\n\nTeniendo en cuenta esta recomendación, te sugerimos la siguiente información 👉🏼`,
        },
      };
    }
    return {
      id: 0,
      flow_id: 0,
      type: StepDataType.TEXT,
      params: {
        title:
          '¡Excelente noticia! Estás al 100% con tu opinión de cumplimiento.',
        emoji: '💯',
        body: 'Puedes estar tranquilo/a. Todo está en orden y al día, sin pendientes ni sorpresas en el SAT. 😊',
      },
    };
  }, [pendingDeclarations]);

  return <TextStepContent step={textStep} />;
}
