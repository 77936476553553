import { ConfirmModal } from '@components/Modal';
import UserPlans from '@components/UserPlans';
import UploadCiecForm from '@components/UploadCiecForm/UploadCiecForm';
import { Button, Flex } from '@konta/ui';
import useFiscalVisorModalCheckout from './useFiscalVisorModalCheckout';

interface FiscalVisorModalCheckoutProps {
  open: boolean;
  toggleOpen: () => void;
}

export default function FiscalVisorModalCheckout({
  open,
  toggleOpen,
}: FiscalVisorModalCheckoutProps) {
  const {
    formik,
    isCIECInvalid,
    isCIECPending,
    enabledForm,
    isLoading,
    isTaxableEntityCiecStatusValid,
  } = useFiscalVisorModalCheckout();

  return (
    <ConfirmModal
      open={open}
      title={
        isTaxableEntityCiecStatusValid ? 'Adquiere el plan' : 'Sube tu CIEC'
      }
      onClose={toggleOpen}
      centered
      size="lg"
      withoutAcceptBtn
      extraFooterContent={
        <Flex css={{ width: '100%', justifyContent: 'space-between' }}>
          {!isTaxableEntityCiecStatusValid && (
            <Button
              onClick={() => formik.submitForm()}
              css={{ marginLeft: 'auto' }}
              color="primary600"
              loading={isLoading}
            >
              Validar CIEC
            </Button>
          )}
        </Flex>
      }
    >
      <Flex
        gap={16}
        column
        css={{ paddingTop: isTaxableEntityCiecStatusValid ? '0' : '$24' }}
      >
        {isTaxableEntityCiecStatusValid ? (
          <UserPlans source="fiscal-visor" asCards />
        ) : (
          <UploadCiecForm
            formik={formik}
            isCIECInvalid={isCIECInvalid}
            isCIECPending={isCIECPending}
            enabledForm={enabledForm}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </ConfirmModal>
  );
}
