import { ReactGrid } from '@silevis/reactgrid';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import useResicoGrid from './useResicoGrid';

interface GridProps {
  satKeys: number[];
  workflows: Workflow[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period?: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
}
export default function ResicoGrid({
  satKeys,
  workflows,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
}: GridProps) {
  const { rows, columns, onSelectionChanged } = useResicoGrid({
    satKeys,
    onHandleEntriesView,
    workflows,
    period,
    selectedAnnualDeclaration,
  });

  return (
    <div
      style={{ height: '100%', overflow: 'auto' }}
      className="taxes-react-grid"
    >
      <ReactGrid
        onSelectionChanged={onSelectionChanged}
        enableFillHandle={false}
        rows={rows}
        columns={columns}
        stickyTopRows={1}
        stickyLeftColumns={2}
      />
    </div>
  );
}
