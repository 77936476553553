import dayjs from 'dayjs';
import { TaxToPayFlowStep } from 'types/flows';
import toCurrency from '@util/toCurrency';
import { ReactComponent as Img } from './img.svg';
import { ImageContainer, Subtitle, TitleContainer } from './styled';

interface TaxToPayStepContentProps {
  taxToPayFlowStep: TaxToPayFlowStep;
}

export default function TaxToPayStepContent({
  taxToPayFlowStep,
}: TaxToPayStepContentProps) {
  const { state } = taxToPayFlowStep;
  const { to_pay } = state;
  const month = dayjs().format('MMMM');
  return (
    <>
      <TitleContainer>
        <span data-type="s">
          Este es el estimado de tus impuestos a pagar en <b>{month}</b>:
        </span>
        <span data-type="l">{toCurrency(+(to_pay || 0))}</span>
      </TitleContainer>
      <ImageContainer>
        <Img />
      </ImageContainer>
      <Subtitle>
        <span data-type="s">
          Este es un cálculo preliminar de tus impuestos a pagar hoy, basado
          solo en tu facturación.
        </span>
        <br />
        <span data-type="s">
          Tienes hasta fin de mes para pedir tus facturas de gastos y así
          reducir el monto a pagar de tus impuestos.
        </span>
      </Subtitle>
    </>
  );
}
