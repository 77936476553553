import { Id, Row } from '@silevis/reactgrid';
import { isSelectedMonth, sumGroupValues } from 'shared/util/grids';
import {
  nonEditable,
  borderLeft,
  borderRight,
  numberCell,
  showZero,
  textCell,
  noBorders,
  leftBorder,
  rightBorder,
  grayBorderTop,
  monthHeaderCell,
  borderLeftRightTop,
  noRightBorder,
  noLeftBorder,
} from 'backoffice/util/cells';
import { getEmptyRow } from 'backoffice/util/rows';
import {
  MONTHS,
  ROW_HEIGHT,
  HEADING_ROW_HEIGHT,
  EMPTY_ROW_ID,
  HEADER_ROW_ID,
} from 'shared/constants/grids';
import type { SimplifiedDataGrid } from 'types/grids';
import { AnnualDeclaration } from 'types/entities';

function getHeaderRow(
  selectedMonth: string | null,
  lastItemName: string,
  months: string[],
  height: number,
  rowId: Id,
  title = '',
): Row {
  const cellMonths = months.map((month) => {
    const cell = monthHeaderCell(month, 'justify-content-center');
    if (isSelectedMonth(month, selectedMonth)) {
      return borderLeftRightTop(cell);
    }
    return cell;
  });
  return {
    rowId,
    height,
    cells: [
      noRightBorder(nonEditable(textCell(title, 'font-bold'))),
      noLeftBorder(
        monthHeaderCell(lastItemName, 'font-bold justify-content-end'),
      ),
      ...cellMonths,
    ],
  };
}

interface GetRowProps {
  title: string;
  values: number[];
  selectedMonth: string | null;
  isTotal?: boolean;
  cursorPointer?: boolean;
  withClickableCells?: boolean;
  withHighDash?: boolean;
  annualTotal?: string;
}

function getRow({
  title,
  values,
  selectedMonth,
  isTotal = false,
  cursorPointer = false,
  withClickableCells = false,
  withHighDash = false,
  annualTotal,
}: GetRowProps): Row {
  const cursorClass = cursorPointer ? 'cursor-pointer corner-indicator' : '';
  const cellMonths =
    values.length === 0
      ? MONTHS.map(() =>
          noBorders(nonEditable(textCell('-', 'justify-content-end'))),
        )
      : values.map((value, idx) => {
          const isCurrentMonth = isSelectedMonth(MONTHS[idx], selectedMonth);

          if (isCurrentMonth) {
            return borderRight(
              borderLeft(nonEditable(numberCell(value, cursorClass))),
            );
          }

          if (isTotal) {
            return grayBorderTop(
              noBorders(
                nonEditable(
                  showZero(numberCell(value, `font-bold ${cursorClass} `)),
                ),
              ),
            );
          }

          return noBorders(nonEditable(numberCell(value, cursorClass)));
        });

  const total = annualTotal ? +annualTotal : sumGroupValues(values);
  const tileClass = isTotal ? 'font-bold gray-border-top' : '';
  const lastClass = isTotal ? 'gray-border-top font-bold' : '';
  return {
    rowId: title,
    height: ROW_HEIGHT,
    cells: [
      leftBorder(noBorders(nonEditable(textCell(title, tileClass)))),
      rightBorder(
        noBorders(nonEditable(showZero(numberCell(total, lastClass)))),
      ),
      ...cellMonths,
    ],
  };
}

interface UseSimplifiedRowsProps {
  dataSet?: SimplifiedDataGrid;
  selectedMonth: string | null;
  title?: string;
  withClickableCells?: boolean;
  period?: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
}

export default function useSimplifiedRows({
  dataSet,
  selectedMonth,
  title,
  withClickableCells,
  period,
  selectedAnnualDeclaration,
}: UseSimplifiedRowsProps): Row[] {
  const annualTotals =
    selectedAnnualDeclaration?.annual_declaration_totals || [];
  const totalIncomes =
    annualTotals.find(
      (annualDeclaration) =>
        annualDeclaration.transaction_type === 'income' &&
        annualDeclaration.source_type === 'resico',
    )?.amount || '0';
  const provisionalPayment =
    annualTotals.find(
      (total) =>
        total.transaction_type === 'provisional_payment' &&
        total.source_type === 'resico',
    )?.amount || '0';
  const taxRetained =
    selectedAnnualDeclaration?.annual_declaration_totals.find(
      (total) =>
        total.transaction_type === 'tax_retained' &&
        total.source_type === 'resico',
    )?.amount || '0';
  const isr = selectedAnnualDeclaration?.isr_tariff || '0';
  const isrInFavorOrAgainst = selectedAnnualDeclaration?.profit_or_loss || '0';

  return [
    getHeaderRow(
      selectedMonth,
      `Anual ${period || ''}`,
      MONTHS,
      HEADING_ROW_HEIGHT,
      HEADER_ROW_ID,
      title,
    ),
    getRow({
      title: 'Ingresos por actividad',
      values: dataSet?.totalIncomes || [],
      selectedMonth,
      isTotal: false,
      cursorPointer: true,
      withClickableCells,
      withHighDash: true,
      annualTotal: totalIncomes,
    }),
    /* getRow({
      title: '(x) Tasa aplicable',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: true,
      withClickableCells,
      withHighDash: true,
    }), */
    getRow({
      title: '(=) ISR causado',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: true,
      withClickableCells,
      withHighDash: true,
      annualTotal: isr,
    }),
    getRow({
      title: '(-) Pagos mensuales ISR',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: true,
      withClickableCells,
      withHighDash: true,
      annualTotal: provisionalPayment,
    }),
    getRow({
      title: '(-) ISR retenido',
      values: [],
      selectedMonth,
      isTotal: false,
      cursorPointer: true,
      withClickableCells,
      withHighDash: true,
      annualTotal: taxRetained,
    }),
    getRow({
      title: `Total ISR a ${
        +isrInFavorOrAgainst > 0 ? 'cargo' : 'favor'
      } del año ${period || ''}`,
      values: [],
      selectedMonth,
      isTotal: true,
      cursorPointer: false,
      withHighDash: true,
      annualTotal: Math.abs(+isrInFavorOrAgainst).toString(),
    }),
    getEmptyRow(
      selectedMonth,
      MONTHS,
      HEADING_ROW_HEIGHT,
      `${EMPTY_ROW_ID}-${1}`,
      false,
    ),
  ];
}
