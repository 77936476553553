import { Flex } from '@konta/ui';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import useResumeByRegime from './useResumeByRegime';
import Grid from './Grid';
import { Root } from './styled';
import { SubTitle, Title, TitleContainer } from '../styled';
import { RESICO_SAT_KEY } from '../../../../shared/constants/grids';
import ResicoGrid from './ResicoGrid';

interface ResumeByRegimeProps {
  workflows: Workflow[];
  satKeys: number[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
}
export default function ResumeByRegime({
  workflows,
  satKeys,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
}: ResumeByRegimeProps) {
  const { title } = useResumeByRegime({ satKeys });
  return (
    <Root>
      <TitleContainer>
        <Title>Declaración anual {title}</Title>
        <SubTitle>Esto es el detalle de tus impuestos durante el año</SubTitle>
      </TitleContainer>
      <Flex column css={{ flex: 1, gap: '40px' }}>
        {satKeys.map((satKey) =>
          satKey === RESICO_SAT_KEY ? (
            <ResicoGrid
              onHandleEntriesView={onHandleEntriesView}
              satKeys={[satKey]}
              workflows={workflows}
              key={satKey}
              period={period}
              selectedAnnualDeclaration={selectedAnnualDeclaration}
            />
          ) : (
            <Grid
              onHandleEntriesView={onHandleEntriesView}
              satKeys={[satKey]}
              workflows={workflows}
              key={satKey}
              period={period}
              selectedAnnualDeclaration={selectedAnnualDeclaration}
            />
          ),
        )}
      </Flex>
    </Root>
  );
}
